<template>
  <section>
    <ag-grid-table ref="grid-historico-skus" :configUrl="historicoSkusConfig" :dataUrl="historicoSkusData" :helper="true"
      :editUrl="historicoSkusEdit" actionsCellRenderer="customActionsCellRender3" :actions="actions"></ag-grid-table>

    <b-modal :ref="'detalle-sku-modal'" ok-only ok-title="Accept" centered size="xl" :title="'Detalle Sku: '">
      <b-card no-body>
        <!-- Card Body  -->
        <b-card-body style="width: 100%">
          <b-list-group v-if="verDetallesData">
            <b-list-group-item v-for="(value, key) in verDetallesData.data['meta_data']" :key="key">
              <div v-if="key != 'recursos'">
                <b>{{ key.replace(/_+/g, ' ') }}:</b> {{ value }}
              </div>
              <div v-if="key == 'recursos'">
                <b>{{ key.replace(/_+/g, ' ') }}:</b>
                <div class="ml-2" v-for="(data, key) in value" :key="key">
                  <b>{{ key.replace(/_+/g, ' ') }}:</b>
                    <ul v-for="(d, k) in data" :key="k">
                      <li v-if="d != 'Desactivado'">{{ k.replace(/_+/g, ' ') }}</li>
                    </ul>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </b-card>
    </b-modal>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
// import SkusListActions from "./SkusListActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {

      historicoSkusConfig: useApiServices.historicoSkusConfig,
      historicoSkusData: useApiServices.historicoSkusData,
      historicoSkusEdit: useApiServices.historicoSkusEdit,
      actions: [
        { name: "Detalles", icon: "AlignJustifyIcon", action: this.verDetalles },
        { name: "Eliminar", icon: "Trash2Icon", action: this.delete },
      ],
      verDetallesData: null,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    ToastificationContent,
    // "skusListActions": SkusListActions
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },

  methods: {

    verDetalles(params) {

      // console.log("verDetalles", params);
      this.verDetallesData = params
      this.$refs['detalle-sku-modal'].show()
    },

    actionReceived(action_name, data, params) {

      console.log({ action_name, data, params })

      if (action_name == "DELETE") {
        this.delete(data)
      }
    },

    delete(data) {
      this.$bvModal.msgBoxConfirm('Confirma si quieres eliminar el sku', {
        title: 'Confirmar',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {

          if (value) {
            useApiServices.postSkusDelete(data.data)
              .then((response) => {

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Eliminación Exitosa`,
                    icon: "UploadIcon",
                    variant: "success",
                    html: `El sku se ha eliminado.`,
                  },
                });

                this.$refs['grid-historico-skus'].refreshRows()
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Ocurrió un Error`,
                    icon: "UploadIcon",
                    variant: "danger",
                    html: `Ha ocurrido un error</b>.`,
                  },
                });

              });


          }
        })
        .catch(err => {
          // An error occurred
        })
    },

    refreshRows() {
      this.$refs['grid-historico-skus'].refreshRows()
    }

  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

